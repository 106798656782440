export class Helper {
  currencyFormatter(value) {
    const currencyFormatter = new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: 2,
    });
    return currencyFormatter.format(value);
  }
}
