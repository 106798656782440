/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateIncome = /* GraphQL */ `
  subscription OnCreateIncome($owner: String!) {
    onCreateIncome(owner: $owner) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateIncome = /* GraphQL */ `
  subscription OnUpdateIncome($owner: String!) {
    onUpdateIncome(owner: $owner) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteIncome = /* GraphQL */ `
  subscription OnDeleteIncome($owner: String!) {
    onDeleteIncome(owner: $owner) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateExpense = /* GraphQL */ `
  subscription OnCreateExpense($owner: String!) {
    onCreateExpense(owner: $owner) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateExpense = /* GraphQL */ `
  subscription OnUpdateExpense($owner: String!) {
    onUpdateExpense(owner: $owner) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteExpense = /* GraphQL */ `
  subscription OnDeleteExpense($owner: String!) {
    onDeleteExpense(owner: $owner) {
      id
      frequency
      description
      startPaymentDate
      status
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateBankAccount = /* GraphQL */ `
  subscription OnCreateBankAccount($owner: String!) {
    onCreateBankAccount(owner: $owner) {
      id
      bank
      bsb
      accountnumber
      description
      status
      openingBalance
      currentBalance
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateBankAccount = /* GraphQL */ `
  subscription OnUpdateBankAccount($owner: String!) {
    onUpdateBankAccount(owner: $owner) {
      id
      bank
      bsb
      accountnumber
      description
      status
      openingBalance
      currentBalance
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteBankAccount = /* GraphQL */ `
  subscription OnDeleteBankAccount($owner: String!) {
    onDeleteBankAccount(owner: $owner) {
      id
      bank
      bsb
      accountnumber
      description
      status
      openingBalance
      currentBalance
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateTransaction = /* GraphQL */ `
  subscription OnCreateTransaction($owner: String!) {
    onCreateTransaction(owner: $owner) {
      id
      description
      transactionDate
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
      }
      incomeID
      income {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        bankAccount {
          id
          bank
          bsb
          accountnumber
          description
          status
          openingBalance
          currentBalance
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      expenseID
      expense {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        bankAccount {
          id
          bank
          bsb
          accountnumber
          description
          status
          openingBalance
          currentBalance
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction($owner: String!) {
    onUpdateTransaction(owner: $owner) {
      id
      description
      transactionDate
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
      }
      incomeID
      income {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        bankAccount {
          id
          bank
          bsb
          accountnumber
          description
          status
          openingBalance
          currentBalance
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      expenseID
      expense {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        bankAccount {
          id
          bank
          bsb
          accountnumber
          description
          status
          openingBalance
          currentBalance
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteTransaction = /* GraphQL */ `
  subscription OnDeleteTransaction($owner: String!) {
    onDeleteTransaction(owner: $owner) {
      id
      description
      transactionDate
      amount
      bankAccountID
      bankAccount {
        id
        bank
        bsb
        accountnumber
        description
        status
        openingBalance
        currentBalance
        createdAt
        updatedAt
        owner
      }
      incomeID
      income {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        bankAccount {
          id
          bank
          bsb
          accountnumber
          description
          status
          openingBalance
          currentBalance
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      expenseID
      expense {
        id
        frequency
        description
        startPaymentDate
        status
        amount
        bankAccountID
        bankAccount {
          id
          bank
          bsb
          accountnumber
          description
          status
          openingBalance
          currentBalance
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
