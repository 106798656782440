<template>
  <b-container>
    <br />
    <b-row>
      <b-col md="12">
        <h3>Expense Details</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <h4>Expenses Summary</h4>
          <br />
          <b-row
            ><b-col md="3"></b-col>
            <b-col md="6">
              Choose how to view your budget
              <b-form-select
                v-model="expenseDisplaySelector"
                @change="changeExpenseDisplay"
              >
                <b-select-option value="Weekly">Weekly</b-select-option>
                <b-select-option value="Fortnightly"
                  >Fortnightly</b-select-option
                >
                <b-select-option value="Monthly">Monthly</b-select-option>
                <b-select-option value="Quarterly">Quarterly</b-select-option>
                <b-select-option value="4-Monthly">4-monthly</b-select-option>
                <b-select-option value="Half Yearly"
                  >Half yearly</b-select-option
                >
                <b-select-option value="Yearly">Yearly</b-select-option>
              </b-form-select> </b-col
            ><b-col md="3"></b-col>
          </b-row>
          <b-row></b-row>
          <b-row
            ><b-col md="3"></b-col
            ><b-col md="3"> Total Expenses: {{ format(totalExpenses) }} </b-col
            ><b-col md="3"></b-col><b-col md="3"></b-col
          ></b-row>
          <b-row
            ><b-col md="3"></b-col
            ><b-col md="3"> Income Balance: {{ format(incomeBalance) }} </b-col
            ><b-col md="3"></b-col><b-col md="3"></b-col
          ></b-row>
          <br />
          <b-table
            ref="accountAmounts"
            :items="accountTransfersPerFrequency"
          ></b-table>
        </b-card>
        <br />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <h4>Add Expense Details</h4>
        <div>
          Add details of all your expenses so that you can see where your money
          goes
        </div>
        <br />
        <b-form>
          <b-form-row>
            <b-col md="3">
              <b-form-input
                v-model="newExpenseItem.description"
                type="text"
                placeholder="description"
              ></b-form-input>
            </b-col>
            <b-col md="2">
              <b-form-input
                id="expenseAmount"
                v-model="newExpenseItem.amount"
                placeholder="amount"
                lazy-formatter
                :formatter="format"
                :number="true"
                :trim="true"
                @focus="removeCurrencyFormatting"
              ></b-form-input>
            </b-col>
            <b-col md="2">
              <b-form-select
                v-model="newExpenseItem.bankAccountID"
                :options="listBankAccounts"
              >
              </b-form-select>
            </b-col>
            <b-col md="2">
              <b-form-select v-model="newExpenseItem.frequency">
                <b-select-option value="Weekly">Weekly</b-select-option>
                <b-select-option value="Fortnightly"
                  >Fortnightly</b-select-option
                >
                <b-select-option value="Monthly">Monthly</b-select-option>
                <b-select-option value="Quarterly">Quarterly</b-select-option>
                <b-select-option value="4-Monthly">4-monthly</b-select-option>
                <b-select-option value="Half Yearly"
                  >Half yearly</b-select-option
                >
                <b-select-option value="Yearly">Yearly</b-select-option>
              </b-form-select>
            </b-col>
            <b-col md="2">
              <b-form-datepicker
                v-model="newExpenseItem.startPaymentDate"
                locale="au"
              ></b-form-datepicker>
            </b-col>
            <b-col md="1">
              <b-button type="submit" @click.prevent="createExpense">
                Submit
              </b-button>
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
    </b-row>
    <br />

    <b-row>
      <b-col md="12">
        <h4>Current Expenses</h4>
        <b-table ref="expenseTable" :items="expenseList" :fields="fields">
          <template #cell(description)="data">
            <template v-if="edit != data.item.id">
              <div>{{ data.item.description }}</div>
            </template>
            <b-form-input v-else v-model="data.item.description" />
          </template>
          <template #cell(amount)="data">
            <template v-if="edit != data.item.id">
              <div>{{ format(data.item.amount) }}</div>
            </template>
            <b-form-input v-else v-model="data.item.amount" />
          </template>

          <template #cell(amountPerBudgetFrequency)="data">
            <div>
              {{ format(data.item.amountPerBudgetFrequency) }} /
              {{ expenseDisplaySelector }}
            </div>
          </template>
          <template #cell(frequency)="data">
            <template v-if="edit !== data.item.id">
              <div>{{ data.item.frequency }}</div>
            </template>
            <b-form-select v-else v-model="data.item.frequency">
              <b-select-option value="Weekly">Weekly</b-select-option>
              <b-select-option value="Fortnightly">Fortnightly</b-select-option>
              <b-select-option value="Monthly">Monthly</b-select-option>
              <b-select-option value="Quarterly">Quarterly</b-select-option>
              <b-select-option value="4-Monthly">4-monthly</b-select-option>
              <b-select-option value="Half Yearly">Half yearly</b-select-option>
              <b-select-option value="Yearly">Yearly</b-select-option>
            </b-form-select>
          </template>
          <template #cell(startPaymentDate)="data">
            <template v-if="edit !== data.item.id">
              <div>{{ data.item.startPaymentDate }}</div>
            </template>
            <b-form-datepicker
              v-else
              v-model="data.item.startPaymentDate"
              locale="au"
            ></b-form-datepicker>
          </template>
          <template #cell(bankAccount)="data">
            <template v-if="edit !== data.item.id">
              <div>{{ data.item.bankAccount.description }}</div>
            </template>
            <b-form-select
              v-else
              v-model="data.item.bankAccountID"
              :options="listBankAccounts"
            >
            </b-form-select>
          </template>
          <template #cell(actions)="data">
            <b-button-group>
              <b-button
                v-if="edit !== data.item.id"
                size="sm"
                variant="outline-secondary"
                @click="editExpense(data.item)"
              >
                <b-icon icon="pencil-square" />
              </b-button>
              <b-button
                v-else
                size="sm"
                variant="success"
                @click="editExpense(data.item)"
              >
                <b-icon icon="upload" />
              </b-button>
              <b-button
                v-if="edit !== data.item.id"
                size="sm"
                variant="danger"
                @click="deleteExpense(data.item.id)"
              >
                <b-icon icon="trash" />
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <br />
  </b-container>
</template>

<script>
import { API, graphqlOperation } from 'aws-amplify';
import { mapGetters, mapActions } from 'vuex';

//import amplify graphql operations
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import * as subscriptions from '@/graphql/subscriptions';

import { Calculator } from '@/classes/Calculator';
import { Helper } from '@/classes/Helper';

export default {
  data() {
    return {
      edit: null,
      listIncomeItems: [],
      listBankAccounts: [],
      totalIncome: 0,
      totalExpenses: 0,
      fields: [
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'amount', label: 'Amount', sortable: true },
        {
          key: 'amountPerBudgetFrequency',
          label: 'Amount Per Budget Frequency',
          sortable: false,
        },
        {
          key: 'frequency',
          label: 'Frequency',
          sortable: true,
        },
        {
          key: 'startPaymentDate',
          label: 'Payment Start Date',
          sortable: false,
        },
        { key: 'bankAccount', label: 'Allocated Bank Account', sortable: true },
        { key: 'actions', label: '', sortable: false, filterable: false },
      ],
      expenseDisplaySelector: 'Weekly',
      newExpenseItem: {
        description: '',
        amount: 0.0,
        bankAccountID: '',
        frequency: 'Weekly',
        startPaymentDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
        ),
        status: 'ACTIVE',
      },
    };
  },
  computed: {
    ...mapGetters({ currentUser: 'user' }),
    ...mapGetters(['expenseList']),
    incomeBalance() {
      return this.totalIncome - this.totalExpenses;
    },
    accountTransfersPerFrequency() {
      if (this.edit === null) {
        this.updateExpenseList({ frequency: this.expenseDisplaySelector });
      }
      const accountAmountArray = [];

      this.expenseList.forEach(item => {
        if (
          !accountAmountArray.some(element => {
            return element.account === item.bankAccount.description;
          })
        ) {
          let total = 0;

          this.expenseList.forEach(element => {
            if (element.bankAccount.id === item.bankAccount.id) {
              total += Number(element.amountPerBudgetFrequency);
            }
          });

          accountAmountArray.push({
            account: item.bankAccount.description,
            amount: this.format(total),
          });
        }
      });
      return accountAmountArray;
    },
  },
  async mounted() {
    //Get the current logged in user
    let that = this;
    //Create a subscription to listen for newly created items
    API.graphql(
      graphqlOperation(subscriptions.onCreateExpense, {
        owner: this.currentUser.username,
      }),
    ).subscribe({
      //Push the new items onto our listBankAccounts array for dispay
      next: () => {
        that.updateExpenseList({ frequency: that.expenseDisplaySelector });
        that.updateExpenseTotal();
        //clear the form
        that.newExpenseItem = {
          description: '',
          amount: 0.0,
          bankAccountID: '',
          frequency: 'Weekly',
          startPaymentDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
          ),
          status: 'ACTIVE',
        };
      },
    });
    //Create a subscription to listen for deleted items
    API.graphql(
      graphqlOperation(subscriptions.onDeleteExpense, {
        owner: this.currentUser.username,
      }),
    ).subscribe({
      next: () => {
        //Remove the item from our listBankAccounts array for dispay
        that.updateExpenseList({ frequency: this.expenseDisplaySelector });
        that.updateExpenseTotal();
      },
    });
  },
  async created() {
    await this.updateExpenseList({ frequency: this.expenseDisplaySelector });

    this.loadBankAccounts();

    // Get all expense list items to display on page load
    const data = {
      ...(await API.graphql(graphqlOperation(queries.listIncomes))).data,
    };
    data.listIncomes.items.forEach(item => {
      this.listIncomeItems.push(item);
    });

    this.updateExpenseTotal();
  },
  methods: {
    ...mapActions(['updateExpenseList', 'addExpense', 'updateExpense']),
    async loadBankAccounts() {
      const { data } = await API.graphql(
        graphqlOperation(queries.listBankAccounts),
      );
      data.listBankAccounts.items.forEach(item => {
        this.listBankAccounts.push({ value: item.id, text: item.description });
      });
    },
    updateExpenseTotal() {
      this.totalExpenses = 0;
      this.expenseList.forEach(item => {
        this.totalExpenses += new Calculator().getWeeklyAmount(item);
      });
      this.changeExpenseDisplay(this.expenseDisplaySelector);
    },
    changeExpenseDisplay(value) {
      this.totalExpenses = 0;
      this.updateExpenseList({ frequency: value });
      this.expenseList.forEach(item => {
        if (value === 'Weekly')
          this.totalExpenses += new Calculator().getWeeklyAmount(item);
        if (value === 'Fortnightly')
          this.totalExpenses += new Calculator().getFortnightlyAmount(item);
        if (value === 'Monthly')
          this.totalExpenses += new Calculator().getMonthlyAmount(item);
        if (value === 'Quarterly')
          this.totalExpenses += new Calculator().getQuarterlyAmount(item);
        if (value === '4-Monthly')
          this.totalExpenses += new Calculator().getFourMonthlyAmount(item);
        if (value === 'Half Yearly')
          this.totalExpenses += new Calculator().getHalfYearlyAmount(item);
        if (value === 'Yearly')
          this.totalExpenses += new Calculator().getYearlyAmount(item);
      });
      this.changeIncomeDisplay(value);
      this.$refs.expenseTable.refresh();
      this.$refs.accountAmounts.refresh();
    },

    changeIncomeDisplay(value) {
      this.totalIncome = 0;
      this.listIncomeItems.forEach(item => {
        if (value === 'Weekly')
          this.totalIncome += new Calculator().getWeeklyAmount(item);
        if (value === 'Fortnightly')
          this.totalIncome += new Calculator().getFortnightlyAmount(item);
        if (value === 'Monthly')
          this.totalIncome += new Calculator().getMonthlyAmount(item);
        if (value === 'Quarterly')
          this.totalIncome += new Calculator().getQuarterlyAmount(item);
        if (value === '4-Monthly')
          this.totalIncome += new Calculator().getFourMonthlyAmount(item);
        if (value === 'Half Yearly')
          this.totalIncome += new Calculator().getHalfYearlyAmount(item);
        if (value === 'Yearly')
          this.totalIncome += new Calculator().getYearlyAmount(item);
      });
    },
    removeCurrencyFormatting(field) {
      field.target.value = Number(field.target.value.replace(/[^0-9.-]+/g, ''));
    },
    // call our graph mutation to add a new shopping list item
    async createExpense() {
      await this.addExpense(this.newExpenseItem);
    },
    // call our graph mutation to add a new shopping list item
    async deleteExpense(expenseID) {
      API.graphql(
        graphqlOperation(mutations.deleteExpense, {
          input: { id: expenseID },
        }),
      );
    },
    editExpense(expenseItem) {
      this.edit = this.edit !== expenseItem.id ? expenseItem.id : null;
      this.updateExpense(expenseItem);
    },
    format: value => new Helper().currencyFormatter(value),
  },
};
</script>
