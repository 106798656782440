<template>
  <b-container>
    <b-row>
      <b-col md="12">
        <h3>Bank Account Details</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <h4>Add Bank Account Details</h4>
          Add details of all your bank accounts so that you can use them to
          control your budget
        </b-card>
        <br />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form>
          <b-form-row>
            <b-col md="3">
              <b-form-input
                v-model="newBankAccount.description"
                placeholder="description"
                @keydown.enter.prevent="addBankAccount"
              ></b-form-input>
            </b-col>
            <b-col md="1">
              <b-form-input
                v-model="newBankAccount.openingBalance"
                type="number"
                placeholder="balance"
                @keydown.enter.prevent="addBankAccount"
              ></b-form-input>
            </b-col>
            <b-col md="2">
              <b-form-input
                v-model="newBankAccount.bank"
                type="text"
                placeholder="bank"
                @keydown.enter.prevent="addBankAccount"
              ></b-form-input>
            </b-col>
            <b-col md="1">
              <b-form-input
                v-model="newBankAccount.bsb"
                type="text"
                placeholder="bsb"
                @keydown.enter.prevent="addBankAccount"
              ></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-input
                v-model="newBankAccount.accountnumber"
                type="text"
                placeholder="account number"
                @keydown.enter.prevent="addBankAccount"
              ></b-form-input>
            </b-col>
            <b-col md="2">
              <b-button variant="primary" @click.prevent="addBankAccount">
                Add
              </b-button>
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
    </b-row>
    <br />

    <b-row>
      <b-col md="12">
        <h4>Current Bank Account Details</h4>
        <b-table :items="listBankAccounts" :fields="fields">
          <template #cell(actions)>
            <b-button
              size="sm"
              variant="danger"
              @click="deleteBankAccount(item.id)"
            >
              Delete
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <br />
  </b-container>
</template>

<script>
//import Amplify
import { Auth, API, graphqlOperation } from 'aws-amplify';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';
import * as subscriptions from '@/graphql/subscriptions';

export default {
  data() {
    return {
      listBankAccounts: [],
      fields: [
        {
          key: 'description',
          label: 'Description',
          sortable: true,
          sortDirection: 'desc',
        },
        { key: 'openingBalance', label: 'Opening Balance', sortable: false },
        { key: 'currentBalance', label: 'Current Balance', sortable: false },
        {
          key: 'bank',
          label: 'Bank',
          sortable: true,
        },
        {
          key: 'bsb',
          label: 'BSB',
          sortable: false,
        },
        {
          key: 'accountnumber',
          label: 'Account Number',
          sortable: false,
        },
        { key: 'actions', label: '', sortable: false, filterable: false },
      ],
      newBankAccount: {
        bank: '',
        bsb: '',
        accountnumber: '',
        description: '',
        status: 'ACTIVE',
        openingBalance: 0,
        currentBalance: 0,
      },
      //sub: '',
    };
  },
  async mounted() {
    //Get the current logged in user
    let currentUser = await Auth.currentAuthenticatedUser();
    let that = this;
    //Create a subscription to listen for newly created items
    API.graphql(
      graphqlOperation(subscriptions.onCreateBankAccount, {
        owner: currentUser.username,
      }),
    ).subscribe({
      //Push the new items onto our listBankAccounts array for dispay
      next: action => {
        that.listBankAccounts.push(action.value.data.onCreateBankAccount);
      },
    });
    //Create a subscription to listen for deleted items
    API.graphql(
      graphqlOperation(subscriptions.onDeleteBankAccount, {
        owner: currentUser.username,
      }),
    ).subscribe({
      next: action => {
        //Remove the item from our listBankAccounts array for dispay
        let id = action.value.data.onDeleteBankAccount.id;
        let index = that.listBankAccounts.map(x => x.id).indexOf(id);
        that.listBankAccounts.splice(index, 1);
      },
    });
  },
  async created() {
    // Get all shopping list items to display on page load
    const { data } = await API.graphql(
      graphqlOperation(queries.listBankAccounts),
    );
    data.listBankAccounts.items.forEach(item => {
      this.listBankAccounts.push(item);
    });
  },
  methods: {
    // call our graph mutation to add a new shopping list item
    async addBankAccount() {
      this.newBankAccount.currentBalance = this.newBankAccount.openingBalance;
      await API.graphql(
        graphqlOperation(mutations.createBankAccount, {
          input: this.newBankAccount,
        }),
      );
      //clear textbox
      this.newBankAccount = {
        bank: '',
        bsb: '',
        accountnumber: '',
        description: '',
        status: 'ACTIVE',
        openingBalance: 0,
        currentBalance: 0,
      };
    },
    // call our graph mutation to add a new shopping list item
    async deleteBankAccount(accountID) {
      API.graphql(
        graphqlOperation(mutations.deleteBankAccount, {
          input: { id: accountID },
        }),
      );
    },
  },
};
</script>
